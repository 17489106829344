var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Date ")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
                defaultDate: new Date(),
              }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ledger")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"ledger"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","options":_vm.ledgerOption,"label":"name"},model:{value:(_vm.ledger),callback:function ($$v) {_vm.ledger=$$v},expression:"ledger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Narration")]),_c('b-form-input',{attrs:{"placeholder":"Enter Narration"},model:{value:(_vm.narration),callback:function ($$v) {_vm.narration=$$v},expression:"narration"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Branch")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.branchOption},model:{value:(_vm.branchname),callback:function ($$v) {_vm.branchname=$$v},expression:"branchname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cheque Name")]),_c('b-form-input',{attrs:{"placeholder":"Enter Cheque Name"},model:{value:(_vm.chequename),callback:function ($$v) {_vm.chequename=$$v},expression:"chequename"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cheque No")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Cheque No"},model:{value:(_vm.chequeno),callback:function ($$v) {_vm.chequeno=$$v},expression:"chequeno"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cheque Date ")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),

                defaultDate: new Date(),
              }},model:{value:(_vm.chequedate),callback:function ($$v) {_vm.chequedate=$$v},expression:"chequedate"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Payment Info.","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticStyle:{"width":"100%","overflow":"auto"}},[_c('table',{staticClass:"table table-bordered",staticStyle:{"width":"28.9cm"}},[_c('thead',{staticClass:"text"},[_c('tr',[_c('th',{staticStyle:{"width":"1cm"}},[_vm._v("ID")]),_c('th',{staticStyle:{"width":"5cm"}},[_vm._v(" ledger"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]),_c('th',{staticStyle:{"width":"5cm"}},[_vm._v("Amount")]),_c('th',{staticStyle:{"width":"1cm"}},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.paymentinfo),function(item,id){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(id + 1))]),_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"ledger"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"y":"","options":_vm.ledgerOption},model:{value:(item.ledger),callback:function ($$v) {_vm.$set(item, "ledger", $$v)},expression:"item.ledger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Enter Amount"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1),_c('td',[_c('b-button',{staticClass:"mt-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeRow(id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)])}),0)])]),_c('b-button',{staticClass:"mt-1 mb-1",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add More")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.submitDisable},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }