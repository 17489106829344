<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Date </label>
              <flat-pickr
                v-model="date"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                  defaultDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Ledger</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="ledger"
              >
                <v-select
                  v-model="ledger"
                  placeholder="None"
                  :options="ledgerOption"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Narration</label>
              <b-form-input v-model="narration" placeholder="Enter Narration" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <v-select
                  v-model="branchname"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="branchOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cheque Name</label>
              <b-form-input
                v-model="chequename"
                placeholder="Enter Cheque Name"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
                        <b-form-group>
                            <label>Display AC Payee</label>
                            <v-select
                                v-model="displayacpayee"
                                placeholder="None"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="name"
                                :options="['Yes','No']"
                            />
                        </b-form-group>
                    </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Cheque No</label>
              <b-form-input
                type="number"
                v-model="chequeno"
                placeholder="Enter Cheque No"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cheque Date </label>
              <flat-pickr
                v-model="chequedate"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),

                  defaultDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Payment Info." no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 28.9cm">
                <thead class="text">
                  <tr>
                    <th style="width: 1cm">ID</th>
                    <th style="width: 5cm">
                      ledger<label style="color: red !important">*</label>
                    </th>
                    <th style="width: 5cm">Amount</th>
                    <th style="width: 1cm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in paymentinfo" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="ledger"
                      >
                        <v-select
                          v-model="item.ledger"
                          placeholder="None"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          y
                          :options="ledgerOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amount"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="mt-1 mb-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button class="ml-1" variant="primary" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
  },
  data() {
    return {
      date: moment(new Date()).format("DD/MM/YYYY"),
      ledger: "",
      narration: "",
      branchname: "",
      chequename: "",
      chequeno: "",
      chequedate: moment(new Date()).format("DD/MM/YYYY"),
      paymentinfo: [
        {
          ledger: "",
          amount: "",
        },
      ],
      test: "",

      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ledgerOption: [],
      branchOption: [],
      id: "",
      ifEdit: false,
      submitDisable: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Contra") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/contra");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/contra");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getcontraById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.paymentinfo = [];
      item.map((item) => {
        this.date =item.date;
        this.ledger = this.getLedger(item.ledger_id);
        this.narration = item.narration;
        this.branchname = this.getBranch(item.branch);
        this.chequename = item.chequename;
        this.chequeno = item.chequeno;
        this.chequedate =item.cheque_date;
        this.test = item.contraitem;
      });
      this.test.map((item) => {
        this.paymentinfo.push({
          ledger: item.Ledgername,
          amount: item.amount,
          Ledgername: item.ledger,
        });
      });
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success == true) {
          this.getLedgerPayment();
          data = {
            date: this.date,
            ledger_id: this.ledger.id,
            narration: this.narration,
            branch: this.branchname.id,
            chequename: this.chequename,
            chequeno: this.chequeno,
            cheque_date: this.chequedate,
            data: this.paymentinfo,
          };
        }

        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.paymentinfo.length !== 0) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/contra/${this.$route.params.id}`
              : `${baseApi}/contra`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/accounting/contra");
            })
            .catch((error) => {

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }).finally(()=>{
              this.submitDisable = false;

            })
        }
      });
    },
    onClickBack() {
      this.$router.push("/accounting/contra");
    },
    async init() {
      this.getLedger();
      this.getBranch();
    },
    async getLedger(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/ledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.ledgerOption = response.data.data.data;
          this.ledgerOption.map((item) => {
            if (item.id == id) {
              this.ledger = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    getLedgerPayment() {
      if (this.paymentinfo.length == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            variant: "danger",
            text: "Please Enter Payment Information ",
          },
        });
      } else {
        this.paymentinfo.map((item) => {
          if (!item.Ledgername) {
            item.ledger = item.ledger.id;
          } else if (item.ledger.id) {
            const x = item.ledger;
            item.ledger = x.id;
            item.Ledgername = x.name;
          } else {
            const y = item.ledger;
            item.ledger = item.Ledgername;
            item.Ledgername = y;
          }
        });
      }
      // this.paymentinfo.map((item)=>{
      //     item.ledger=item.Ledgername
      // })

      // this.ledgerOption.map((item)=>{
      //      this.test.map((data)=>{
      //       if(item.id == data.ledger){
      //      this.paymentinfo.push({
      //             ledger: item.name,
      //             amount: data.amount
      //     })

      //       }
      //   })
      //   })
    },
    async getBranch(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchOption = response.data.data.data;
          this.branchOption.map((item) => {
            if (item.id == this.id) {
              this.branchname = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    removeRow(id) {
      this.paymentinfo.splice(id, 1);
    },
    addRow() {
      this.paymentinfo.push({
        ledger: "",
        amount: "",
      });
    },
  },
};
</script>
